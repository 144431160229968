/* *
*  活动标签相关API
*/
import request from '@/utils/request'

/**
 * 添加活动标签
 * @param params
 */
export function activeLabelAdd(params) {
  return request({
    url: '/admin/active/label/add',
    method: 'post',
    loading: false,
    params,
    log: {
      type: '0',
      name: '活动标签添加'
    }
  })
}

/**
 * 查询活动标签列表
 * @param params
 */
export function activeLabelPage(params) {
  return request({
    url: '/admin/active/label/page',
    method: 'post',
    params
  })
}

/**
 * 修改标签
 * @param params
 */
export function activeLabelEdit(params) {
  return request({
    url: '/admin/active/label/edit',
    method: 'post',
    params,
    log: {
      type: '0',
      name: '活动标签修改'
    }
  })
}

/**
 * 删除标签
 * @param id
 */
export function activeLabelDel(id) {
  return request({
    url: `/admin/active/label/${id}`,
    method: 'delete',
    log: {
      type: '0',
      name: '活动标签删除'
    }
  })
}
